.App-MicrophoneButton {
  align-items: center;
  border-radius: 50%;
  bottom: 4vmin;
  display: flex;
  font-size: 8vmin;
  justify-content: center;
  margin: 0;
  outline: 0;
}

.App-MicrophoneButton>svg {
  fill: White;
}

.App-MicrophoneButton.dictating {
  background: linear-gradient(48.21deg, #3280FC 20.97%, #D18EFA 113.84%);
  box-shadow: #3280FC 3px 3px1 50px;
  opacity: 1;
  border: node;
  position: fixed;
}

.App-MicrophoneButton.dictating>svg {
  fill: White;
  position: fixed;
}



.tooltip-inner {
  background-color: #FFECEA !important;
  border: 2px solid #ED5D4E;
  color: black;
  background: #FFECEA;
border: 1px solid #ED5D4E;
}


abbr {
  text-decoration: none !important;
}

.fade.tooltip-design.show.tooltip.bs-tooltip-top {
  border-bottom: none !important;
  
}
.tooltip-arrow,
.red-tooltip+.tooltip>.tooltip-inner {
  --bs-tooltip-bg: black;
  
}

.tooltip {
  animation: shake 1000ms ease-in-out forwards;
  animation-iteration-count: 2;
}

@keyframes shake {
  0% {
    transform: rotate(2deg);
  }

  50% {
    transform: rotate(-3deg);
  }

  70% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}





.up-arrow {
  display: inline-block;
  position: relative;
  border: 1px solid #777777;
  text-decoration: none;
  border-radius: 2px;
  padding: 20px;
  margin-top: 50px;
}
.up-arrow:before {
  content: '';
  display: block;  
  position: absolute;
  left: 140px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: black;
}

.up-arrow:after {
  content: '';
  display: block;  
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}
.tooltip-arrow::before{
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: #FFECEA !important;
  outline-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  /* outline:#ED5D4E  ; */
  outline-color: #ED5D4E;

}