@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


* {
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

input,
select {
  cursor: pointer;
}

body {
  background: #f5fafd;
  font-family: "Poppins";
  font-weight: 500;
  height: auto !important;
  /* width: 100%; */
  /* overflow: hidden !important; */
}


/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}