.outer-end-modal {
  display: grid;
}

.end-modal-card-main {
  place-self: center;
  background-color: #eff4f5;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}

.display-flex-property{
  display: flex;
  flex-wrap: wrap;
}

.end-modal-card-main-body {
  display: flex;
  justify-content: center;
}

p.end-first-text {
  font-size: 12px;
  color: #a3a3a3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

a.end-second-text {
  margin: 0rem 0.2rem;
  font-size: 12px;
  text-decoration-line: underline;
  color: #034da2;
  font-weight: 400;
}

.end-modal-card-main-body.modal-body {
  margin: 0.1rem 0rem;
}
a {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .display-flex-property{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  padding: 1%;
  }
  .outer-end-modal {
    display: unset !important;
  }
  .end-modal-card-main {
    place-self: center;
    background-color: #eff4f5;
    border-radius: 6px;
    display: flex;
    padding: 0rem 0.2rem;
  }
}
