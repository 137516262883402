.start-btn {
    background-color: #0c4da2 !important;
    color: #fff !important;
    font-size: 16px;
    padding: 5px 0;
    width: 10.6rem;
    text-align: center;
    border-radius: 4px;
    margin: 0.5%;
  }

  .hyper-head{
    font-weight: 500;
  }