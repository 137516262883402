.conversationId-div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}

@media screen and (max-width: 550px) {
  #home {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-img {
    width: 55% !important;
  }
  .conversationId-div {
    font-size: 10px;
    line-height: 12px;
  }
}
.close-icon {
  cursor: pointer;
}

