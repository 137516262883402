.display-choices {
    display: flex;
    flex-wrap: wrap;
}

.indv-price {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1%;
    font-weight: 300;
    padding: 1%;
    background-color: white;
    border-radius: 4px;
}
.choice-head-css{
    font-weight: 500;
}