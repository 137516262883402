.main-component {
  font-family: "Poppins";
}

.zoom-in-zoom-out {
  width: 50%;
  height: auto;
  background-color: none;
  animation: zoom-in-zoom-out 3s ease-out infinite;
}

.modal-body {
  padding: 0 !important;
}

.modalbody {
  border: 1px solid #ebf4ff;
  padding-bottom: 1rem !important;
}

.mic-img-div {
  height: 150px;
  width: 100%;
  overflow: hidden;
}

.mic {
  width: 45%;
}

.tr-bg {
  position: absolute;
  right: -110px;
  top: -110px;
  height: 278px;
  width: 278px;
}

.bl-bg {
  position: absolute;
  bottom: -65px;
  left: -65px;
  height: 190px;
  width: 190px;
}

.back-div {
  display: none !important;
  position: absolute;
  height: 30px;
  width: 30px;
  left: 30px;
  top: 25px;
  background-color: #f4faff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
}

.modal-content {
  overflow: hidden;
}

.backsvg {
  width: 25%;
}

.welcome-text {
  color: #0c4da2 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  /* identical to box height, or 114% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.14px;
}

.welcome-sub-text {
  padding: 0rem 0rem 1rem 0rem;
  font-size: 14px;
  color: #0c4da2 !important;
}

.start-btn {
  background-color: #0c4da2 !important;
  color: #fff !important;
  font-size: 12px !important;
  padding: 10px 10px;
  width: 12.6rem;
  text-align: center;
  border-radius: 4px;
}

button.start-btn.btn-outline-light.m-2.btn.btn-primary {
  width: 12rem !important;
  font-size: small !important;
}

.permission-text {
  font-size: 16px;
  font-weight: 600;
}

.permission-sub-text {
  /* font-size: 12px;
  font-weight: 400;
  color: #0c4da2 !important;
  display: flex;
  align-items: center */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.14px;

  color: #808080;
}

.permissionsvg {
  width: 60%;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

.vertical-line {
  /* width: 1px; /* Line width */
  /* background-color: #808080; /* Line color */
  /* height: 15px !important; */

  border: 0.5px solid #808080;

  height: 15px;
  /* Causes the line to float to left of content. 
    You can instead use position:absolute or display:inline-block
    if this fits better with your design */
}

.PermissionToastCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

@media only screen and (max-width: 550px) {
  /* .tr-bg {height: 225px;
    width: 225px;}  */

  .modal-dialog-centered {
    align-items: normal !important;
  }

  .fade.modal-backdrop.show {
    display: none !important;
  }

  .modal-content {
    overflow: hidden !important;
    border-radius: 0rem !important;
    border: none !important;
    margin: 1.6rem 0rem 0rem 0rem !important;
  }

  .modal-dialog.modal-dialog-centered {
    margin: 1rem 0rem 0rem 0rem !important;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}