.parent-summary-div {
    background-color: white;
    padding: 2%;
    border-radius: 4px;
    color: black;
    width: 100%;
}

.name-title {
    display: flex;
    justify-content: space-between;

}

.key-value-pairset {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
}

.name-div {
    margin-left: 4%;
}

.val-div2 {
    font-weight: 600;
    margin-left: 4%;
}

.headaer-summ {
    font-weight: 500;
}