.toast-parentcard {
    width: fit-content;
    z-index: 4500;
    position: relative;
    top: 1.2rem;
    height: 64px;
}

.toast-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    gap: 26px;
    background: #fff4f4;
    border-left: 6px solid #FFB800;
    border-radius: 6px;

}

.text-permission {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    display: flex;
    align-items: center;
    letter-spacing: 0.14px;
    color: #000000;
}

@media screen and (max-width: 550px) {
    .toast-parentcard {

        position: relative;
        top: 1.4rem;
        width: 90%;
    }

    .toast-body {
        box-sizing: border-box;

        /* Auto layout */

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 6px;
        gap: 26px;
        background: #FFFBF0;
        border-left: 6px solid #FFB800;
        border-radius: 6px;
    }

}




@media (min-width: 550px) and (max-width:800px) {

    .toast-parentcard {

        position: relative;
        top: -1rem;

    }
}