.App-SpeechInterims {
  color:  black;
  display: flex;
  font-size: 10vmin;
  height: 100vh;
  left: 0;
  
  top: 0;
  width: 100vw;
  position: fixed;
}

.App-SpeechInterims > span {
  margin: 4vmin;
}
