.chat-box-bot-outer{
  display: flex;
  flex-direction: column;
}

.chat-outside {
  width: 100% !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.chat-main-div {
  padding: 3rem 2.5rem 4rem 2.5rem;
  width: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: center;
}

.chat-main {
  padding: 1rem 2rem;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 95%;
  width: 100%;
  border-radius: 8px;
  overflow: scroll;

}

.chat-main::-webkit-scrollbar {
  display: none;
}


.chat-right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-items: flex-start;
}

.chat-box-user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "Poppins";
  background-color: #00b6e1;
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 8px;
  letter-spacing: normal !important;
  box-shadow: rgba(190, 190, 190, 0.25) 0px 4px 8px 0px;
  margin: 1%;
}

.chat-left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
}

.chat-box-bot {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: Poppins;
  background-color: #ffffff;
  color: #034da2;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  border-radius: 8px;
  margin: 1%;
  box-shadow: rgba(190, 190, 190, 0.25) 0px 4px 8px 0px;
  box-sizing: border-box;
}

.user-bot-avtar {
  max-width: 50px;
}

.chat-box-user,
.chat-box-bot {
  width: 100%;
  font-size: 14px;
}

.chat-box-bot div {
  width: 100%;
  text-align: justify;
}

.chat-box-user div {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  /* text-align: justify; */
}

.chat-btns-div {
  position: fixed;
  z-index: 1;
  bottom: 30px;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  background-color: #f8feff;
  padding: 8px 75px;
  border-radius: 30px;
  box-shadow: rgb(231 231 231 / 25%) 0px 8px 4px;
}

.chat-btn {
  height: 40px;
  width: 40px;
}




.mute-button {
  cursor: pointer;
}

.chat-box-bot {
  background-color: #EFF8FF;
}

.condition-css-chat-box-bot {
  width: 50%;
}

.time-stamp-user{
  color: #9D9D9D;
  font-size: xx-small;
  display: flex;
  padding: 0.2rem 0rem 0.2rem 0rem;
  justify-content: flex-end;
}

.time-stamp-bot {
  color: #9D9D9D;
  font-size: xx-small;
  display: flex;
  padding: 0.2rem 0rem 0.5rem 1.2rem;
  justify-content: flex-start;
}


.chatuser-with-ts{
  display: flex;

  flex-direction: column;
  justify-content :flex-end;
  align-items: flex-end;
}

@media screen and (max-width: 550px) {
  .chat-main-div {
    padding: 2rem 1rem 3rem 1rem;
  }

  .chat-main {
    padding: 1rem 0 4rem 0rem;
  }

  .chat-box-user,
  .chat-box-bot {
    width: 90%;
    max-width: 90%;
  
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
  }

  

  .chat-box-bot {
    margin-left: 15px;
  }

  .chat-box-bot div {
    text-align: start;
  }

  .chat-box-bot div,
  .chat-box-user div {
    font-size: 12px;
  }
/* 
  .chat-box-user {
    margin: 24px 15px 24px 0;
  } */

  .user-bot-avtar {
    max-width: 38px;
    padding-top: 0.4rem;
  }

  #home {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-img {
    width: 75%;
  }

  .chat-btn {
    height: auto;
    width: 100%;
  }
}


@media (min-width: 550px) and (max-width:1600px) {

  .chat-main {
    padding: 1rem 1rem 4rem 1rem;
  }

  .chat-box-user,
  .chat-box-bot {
    width: 100%;
    font-size: 14px;
  }

  .chat-box-bot {
    margin-left: 15px;
  }

  .chat-box-bot div {
    text-align: start;
  }

  .chat-box-bot div,
  .chat-box-user div {
    font-size: 12px;
  }

  /* .chat-box-user {
    margin: 24px 15px 24px 0;
  } */

  .user-bot-avtar {
    max-width: 38px;
    padding-top: 0.2rem;
  }

  #home {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-img {
    width: 75%;
  }

}


